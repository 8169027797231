import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth';
import { handleAuth } from 'libs/canva/utils';

const useCanvaRedirect = () => {
  const { isAuthenticated } = useAuth({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const uuid = Cookies.get('uuid') || crypto.randomUUID();
    // expires after 90 days
    Cookies.set('uuid', uuid, {
      sameSite: 'none',
      secure: true,
      path: '/',
      expires: 90,
    });

    const nonce = searchParams.get('nonce');
    const state = searchParams.get('state');
    const canvaUserId = searchParams.get('user');
    const brandId = searchParams.get('brand');

    if (isAuthenticated) {
      if (canvaUserId && nonce && state) {
        handleAuth({ nonce, state, canvaUserId, brandId });
      } else if (searchParams.size < 2) {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, navigate, searchParams]);
};

export default useCanvaRedirect;
